import {
    AncestorLinkTypes,
    ChildLinkTypes,
    ChildLinks,
    LinkTypes,
    Links,
    Namespaces,
    ParentLinkTypes,
    ParentLinks,
    PayloadLinkTypes,
    PayloadLinks,
    Schema,
    SchemaEntry,
    getListItemToKey,
    isListItemLinkKey,
    isRoleLinkKey,
} from "./schema";

import type { Exactly, Query, QueryResponse } from "@instantdb/core";


type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P];
};

function isObject(item: any): item is object {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

export function deepMerge<T extends object>(target: T, source: DeepPartial<T>, depth: number = Infinity): T {
  if (depth === 0 || !isObject(source) || !isObject(target)) {
      return target;
  }

  for (const key in source) {
      if (source.hasOwnProperty(key)) {
          const targetValue = target[key];
          const sourceValue = source[key];

          if (isObject(targetValue) && isObject(sourceValue)) {
              target[key] = deepMerge(targetValue, sourceValue, depth - 1);
          } else {
              target[key] = sourceValue as any;
          }
      }
  }

  return target;
}

export type NonEmpty<T> = {
  [K in keyof T]-?: Required<Pick<T, K>>;
}[keyof T];

export type WhereArgs = {
  in?: (string | number | boolean)[];
};

export type WhereClause = {
  [key: string]: string | number | boolean | NonEmpty<WhereArgs>;
};

export type CustomExpansionQuery<K extends keyof Namespaces> = {
  [InnerK in LinkTypes[K]]?: CustomExpansionQuery<InnerK> | {};
};

export interface QueryOptions<K extends keyof Namespaces> {
    include_trashed?: boolean;
    expand_ancestors?: boolean;
    expand_descendants?: boolean;
    include_payloads?: PayloadLinkTypes[K][];
    custom_expansion?: CustomExpansionQuery<K>;
    no_default_expansion?: boolean;
    getQueryFn?: <Q extends Query>(query: Query) => Promise<QueryResponse<Q, Schema>>;
}

// ... (previous code remains the same)

function _get_query_links<K extends keyof Namespaces>(
    key: K,
    options?: QueryOptions<K>
): Record<LinkTypes[K], object> {
    return Links[key].reduce((acc, linkedKey) => {
        // Include payload links if they are specified in include_payloads
        if (PayloadLinks[key]?.includes(linkedKey as PayloadLinkTypes[K]) && !options?.include_payloads?.includes(linkedKey as PayloadLinkTypes[K])) {
            return acc;
        }

        // Expand list item links one level deeper
        if (isListItemLinkKey(linkedKey)) {
            const toKey = getListItemToKey(linkedKey);
            acc[linkedKey] = {
                [toKey]: {},
            };
            return acc;
        }

        // Expand roles one level deeper
        if (isRoleLinkKey(linkedKey)) {
            acc[linkedKey] = {
                profile: {},
            };
            return acc;
        }

        acc[linkedKey] = {};
        return acc;
    }, {} as Record<LinkTypes[K], object>);
}

function _get_descendant_links<K extends keyof Namespaces>(
    key: K,
    options?: QueryOptions<K>
): Record<LinkTypes[K], object> {
    function _inner_query_descendants<InnerK extends keyof Namespaces>(innerKey: InnerK): DescendantsQuery {
        const result = deepMerge(
          Object.values(ChildLinks[innerKey] || []).reduce((acc, link) => {
              // Skip payload links in children
              if (!options?.expand_descendants && PayloadLinks[innerKey]?.includes(link as unknown as PayloadLinkTypes[InnerK])) {
                  return acc;
              }

              const { custom_expansion: _, include_payloads, ...rest } = options || {};
              const newOptions: QueryOptions<typeof link> = { ...rest };
              if (link) {
                  acc[link] = deepMerge(
                      _inner_query_descendants(link),
                      _get_query_links(link, newOptions),
                  );
              }
              return acc;
            }, {} as DescendantsQuery),
            Object.values(Links[innerKey] || []).reduce((acc, link) => {
              // Include payload links if they are specified in include_payloads
              if (PayloadLinks[innerKey]?.includes(link as unknown as PayloadLinkTypes[InnerK])) {
                  return acc;
              }

              const { custom_expansion: _, include_payloads, ...rest } = options || {};
              const newOptions: QueryOptions<typeof link> = { ...rest };
              acc[link] = _get_query_links(link, newOptions);
              return acc;
            }, {} as Record<LinkTypes[InnerK], object>),
        );
        return result;
    }

    return {
        ...deepMerge(Object.values(ChildLinks[key] || []).reduce((acc, link) => {
            // Skip payload links in children
            if (!options?.expand_descendants && PayloadLinks[key]?.includes(link as unknown as PayloadLinkTypes[K])) {
                return acc;
            }

            if (link) {
                acc[link] = deepMerge(
                    _inner_query_descendants(link),
                    _get_query_links(link as unknown as K, options),
                );
            }
            return acc;
        }, {} as DescendantsQuery),
        Object.values(Links[key] || []).reduce((acc, link) => {
            // Skip payload links in children
            if (PayloadLinks[key]?.includes(link as unknown as PayloadLinkTypes[K])) {
                return acc;
            }

            const { custom_expansion: _, include_payloads, ...rest } = options || {};
            const newOptions: QueryOptions<typeof link> = { ...rest };
            acc[link] = _get_query_links(link, newOptions);
            return acc;
        }, {} as Record<LinkTypes[K], object>)),
    } as Record<LinkTypes[K], object>;
}

function _get_ancestor_links<K extends keyof Namespaces>(
    key: K,
    options?: QueryOptions<K>,
): Record<LinkTypes[K], object> {
    function _inner_query_ancestors<InnerK extends keyof Namespaces>(innerKey: InnerK): AncestorsQuery {
        const parentKeys = ParentLinks[innerKey];
        const { custom_expansion: _, include_payloads, ...rest } = options || {};
        const newOptions: QueryOptions<InnerK> = {...rest};
        const queryLinks: Query = _get_query_links(innerKey, newOptions);
        return {
          [innerKey]: (parentKeys || [] as any[]).reduce((acc: any, parentKey: any) => {
            return deepMerge(
            acc,
            parentKey ? _inner_query_ancestors(parentKey) : {},
          )}, queryLinks),
        };
    }

    return {
        ..._inner_query_ancestors(key)[key],
    } as Record<LinkTypes[K], object>;

}

export function inject_trashed_clause(obj: Query, include_trashed?: boolean): Exactly<Query, Query> {
    return Object.entries(obj).reduce((acc, [key, value]) => {
        if (typeof value === 'object' && value !== null) {
            if (key === '$') {
                if ('where' in value) {
                    acc[key] = {
                        where: {
                            ...(value as any).where,
                            ...(include_trashed ? undefined : { trashed: false }),
                        },
                    };
                }
            } else {
                acc[key] = inject_trashed_clause(value as Query, include_trashed);
                if (!include_trashed && !('$' in acc[key])) {
                    acc[key]['$'] = {
                        where: { trashed: false },
                    };
                }
            }
        } else {
            acc[key] = value;
        }
        return acc;
    }, {} as Query);
}


function _inject_query_links<K extends keyof Namespaces>(obj: Query, options?: QueryOptions<K>): Record<LinkTypes[K], {}> {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    const link = key as LinkTypes[K];
    if (typeof value === 'object' && value !== null) {
      acc[link] = _inject_query_links(value as Query, options);
      if (!('$' in acc[key as LinkTypes[K]])) {
        const { custom_expansion: _, include_payloads, ...rest } = options || {};
        const newOptions: QueryOptions<LinkTypes[K]> = {...rest};
        acc[link] = deepMerge(
            options?.no_default_expansion ? {} : _get_query_links(link, newOptions),
            acc[link] as object
        );
      }
    } else {
      acc[link] = value;
    }
    return acc;
  }, {} as Record<LinkTypes[K], {}>);
}


function _build_query<K extends keyof Namespaces>(
    key: K,
    where: WhereClause,
    options?: QueryOptions<K>
): Exactly<Query, Query> {
  const query: Exactly<Query, Query> = {
      [key]: {
          ...deepMerge(deepMerge(deepMerge(
            options?.no_default_expansion ? {} : _get_query_links(key, options),
            options?.expand_ancestors ? _get_ancestor_links(key, options) : {}),
            options?.expand_descendants ? _get_descendant_links(key, options) : {}),
            _inject_query_links((options?.custom_expansion || {}) as Query, options),
          ),
          $: {
              where,
          },
      },
  };

  return inject_trashed_clause(query, options?.include_trashed);
}

export function query_custom<K extends keyof Namespaces>(key: K, namespaceVal: Query[string], options?: QueryOptions<K>): Exactly<Query, Query> {
  return inject_trashed_clause({ [key]: namespaceVal }, options?.include_trashed);
}

// ... (rest of the code remains the same)
export function query_one<K extends keyof Namespaces>(key: K, id: string, options?: QueryOptions<K>): Exactly<Query, Query> {
    return _build_query(key, { id }, options);
}

export function query_where<K extends keyof Namespaces>(key: K, where: WhereClause, options?: QueryOptions<K>): Exactly<Query, Query> {
  return _build_query(key, where, options);
}

export function query_split<K extends keyof Namespaces>(key: K, id: string, options?: QueryOptions<K>): Exactly<Query, Query> {
  const include_trashed = { trashed: !options?.include_trashed ? false : undefined };
  return Object.entries(_build_query(key, { id }, options)[key] || {}).reduce((acc, [innerKey, value]) => {
    if (innerKey === "$" || !!(value as any).where) return acc;
    acc[innerKey] = {
      ...value,
      $: {
        // @ts-ignore
        where: {
          ...include_trashed,
          [`${key}.id`]: id,
        },
      },
    };
    return acc;
  }, {
    [key]: {
      $: {
        where: { id, ...include_trashed }
      }
    }
  } as Exactly<Query, Query>);
}

export function query_many<K extends keyof Namespaces>(key: K, ids: string[], options?: QueryOptions<K>): Exactly<Query, Query> {
    return _build_query(key, { id: { in: ids } }, options);
}

export function query_find<K extends keyof Namespaces>(
    key: K,
    where: Partial<Namespaces[K]>,
    options?: QueryOptions<K>
): Exactly<Query, Query> {
    return _build_query(
        key,
        Object.fromEntries(Object.entries(where).filter(([k, v]) => (v !== undefined && v !== null ? [k, v] : v))) as Record<
            keyof Namespaces[K],
            string | number | boolean
        >,
        options
    );
}

// ... (previous code remains the same)

// Recursively builds a query of all descendants of a namespace
export type DescendantsQuery = {
    [K in keyof Namespaces]?: DescendantsQuery | {};
};

export function query_descendants<K extends keyof Namespaces>(
    key: K,
    id: string,
    options?: QueryOptions<K>
): Exactly<Query, Query> {
  return _build_query(key, { id }, {include_payloads: PayloadLinks[key], ...options, expand_descendants: true});
}

// Visits descendants based on the result of a descendants query.

export type DescendantsQueryResponse<K extends keyof Namespaces> = {
  [InnerK in K]: ({
    [P in keyof SchemaEntry<InnerK>]: SchemaEntry<InnerK>[P];
  } & DescendantsQueryResponse<ChildLinkTypes[InnerK]>)[];
}

export function visit_descendants<K extends keyof Namespaces> (
  key: K,
  response: DescendantsQueryResponse<K>,
  visit: <InnerK extends keyof Namespaces>(key: InnerK, response: DescendantsQueryResponse<InnerK>, visited: Set<string>) => void,
  visited = new Set<string>(),
): void {
  const data = response[key];
  if (!Array.isArray(data) || data.length < 1) return;
  data.forEach((entry) => {
    (ChildLinks[key] || []).forEach((childKey: ChildLinkTypes[K]) => {
      visit_descendants(childKey, entry, visit, visited);
    });
  });
  visit(key, response, visited);
  response[key].forEach((entry) => {
    visited.add(entry.id);
  });
}


// Query ancestors
export type AncestorsQuery = {
    [K in keyof Namespaces]?: AncestorsQuery | {};
};

export function query_ancestors<K extends keyof Namespaces>(
    key: K,
    id: string,
    options?: QueryOptions<K>,
): Exactly<Query, Query> {
  return _build_query(key, { id }, { ...options, expand_ancestors: true });
}

// Visit ancestors

export type AncestorsQueryResponse<K extends keyof Namespaces> = {
  [InnerK in K]: ({
    [P in keyof SchemaEntry<InnerK>]: SchemaEntry<InnerK>[P];
  } & (ParentLinkTypes[InnerK] extends keyof Namespaces ? AncestorsQueryResponse<ParentLinkTypes[InnerK]> : undefined)[]);
}

export function visit_ancestors<K extends keyof Namespaces> (
  key: K,
  response: AncestorsQueryResponse<K>,
  visit: <InnerK extends keyof AncestorLinkTypes>(key: InnerK, response: AncestorsQueryResponse<InnerK>) => void,
): void {
  const data = response[key];
  if (!Array.isArray(data) || data.length !== 1) return;
  const entry = data[0];
  if (entry) {
    const parentKeys = ParentLinks[key];
    parentKeys?.forEach((parentKey: ParentLinkTypes[K]) => {
      visit_ancestors(parentKey, entry, visit);
    });
  }
  visit(key, response);
}


// Grandchildren

export function query_grandchildren<K extends keyof Namespaces>(
  key: K,
  id: string,
  options?: QueryOptions<K>
): Exactly<Query, Query> {
  const customExpansion: CustomExpansionQuery<K> = ((ChildLinks[key] || []) as ChildLinkTypes[K][]).reduce(
    (acc: CustomExpansionQuery<K>, childKey: ChildLinkTypes[K]) => {
      acc[childKey as LinkTypes[K]] = ((ChildLinks[childKey] || []) as ChildLinkTypes[ChildLinkTypes[K]][]).reduce(
        (childAcc: CustomExpansionQuery<ChildLinkTypes[K]>, grandchildKey: ChildLinkTypes[ChildLinkTypes[K]]) => {
          childAcc[grandchildKey] = {};
          return childAcc;
        },
        {} as CustomExpansionQuery<ChildLinkTypes[K]>
      );
      return acc;
    },
    {} as CustomExpansionQuery<K>
  );

  return _build_query(key, { id }, { ...options, custom_expansion: customExpansion });
}

// Deep

export function query_deep<K extends keyof Namespaces>(
  key: K,
  id: string,
  depth: number,
  options?: QueryOptions<K>,
  visited: Set<keyof Namespaces> = new Set()
): Exactly<Query, Query> {
  if (visited.has(key)) {
    // Cycle detected, return an empty object to prevent infinite recursion
    return {} as Exactly<Query, Query>;
  }

  visited.add(key);

  const buildExpansion = <T extends keyof Namespaces>(linkKey: T, currentDepth: number): CustomExpansionQuery<T> => {
    if (currentDepth > 0) {
      return (Links[linkKey] || []).reduce(
        (acc, innerLinkKey) => {
          acc[innerLinkKey as LinkTypes[T]] = buildExpansion(innerLinkKey, currentDepth - 1);
          return acc;
        },
        {} as CustomExpansionQuery<T>
      );
    } else {
      return {};
    }
  };

  const customExpansion: CustomExpansionQuery<K> = buildExpansion(key, depth);

  visited.delete(key);

  return _build_query(key, { id }, { ...options, custom_expansion: customExpansion, no_default_expansion: true });
}
