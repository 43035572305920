"use client";

import React, { useEffect } from "react";

import { useAuth as useClerkAuth, useUser } from "@clerk/nextjs";
import { AuthState, InstantObject } from "@instantdb/react";

import { get_instant_token } from "@palette.tools/api.client";
import { UserProfile, auth, transact, useInstantDBAuth } from "@palette.tools/model.client";
import { signInWithCustomToken } from "firebase/auth";
import { firebaseAuth } from "./storage";


const _useAuth: () => Omit<AuthState & { profile: UserProfile | undefined, signOut: (() => Promise<void>) | undefined }, 'user'> = () => {

  const authResult = useInstantDBAuth();
  const { user: clerkUser, isLoaded: clerkUserIsLoaded } = useUser();
  const { getToken, signOut: signOutClerk } = useClerkAuth();
  const [ profile, { isLoading: isLoadingProfile, error: profileError } ] = UserProfile.useDeep(authResult.user?.id, { depth: 1 });

  const [ error, setError ] = React.useState<{message: string} | undefined>(authResult.error || profileError);
  const [ firebaseUser, setFirebaseUser ] = React.useState<typeof firebaseAuth['currentUser']>(firebaseAuth.currentUser);

  const signInFirebase = async () => {
    const token = await getToken({ template: "integration_firebase" });
    await signInWithCustomToken(firebaseAuth, token || "");
  };

  useEffect(() => {
    const unsub = firebaseAuth.onAuthStateChanged(setFirebaseUser);
    return () => unsub();
  }, []);

  useEffect(() => {
    if (clerkUserIsLoaded && !firebaseUser) {
      signInFirebase().catch(e => {
        console.error(e);
        setError(e);
      })
    }
  }, [clerkUserIsLoaded, clerkUser?.primaryEmailAddress?.emailAddress, firebaseUser]);

  useEffect(() => {
    if (clerkUserIsLoaded && !authResult.isLoading && authResult.user?.email !== clerkUser?.primaryEmailAddress?.emailAddress) {
      get_instant_token().then(async (token) => {
        await auth.signInWithToken(token);
      }).catch(e => {
        console.error(e);
        setError(e);
      })
    }

  }, [clerkUserIsLoaded, clerkUser?.primaryEmailAddress?.emailAddress, authResult.isLoading, authResult.user?.email]);

  useEffect(() => {
    if (authResult.user && !isLoadingProfile && !!clerkUser) {
      const transactionData = {
        id: authResult.user.id,
        email: authResult.user.email,
        first: clerkUser.firstName || "New",
        last: clerkUser.lastName || "User",
        clerkId: clerkUser.id,
        image_url: clerkUser.imageUrl,
        last_active: new Date().getTime(),
      }
      if (!profile) {
        transact(
          UserProfile.create(transactionData, { author: authResult.user.id }),
        );
      }
      else {
        transact(
          profile.update(transactionData),
        )
      }
      if ('Atlas' in window) {
        (window as any).Atlas.call(
          "identify",
          {
            userId: authResult.user.id,
            name: clerkUser.fullName,
            email: clerkUser.primaryEmailAddress?.emailAddress,
            fields: {
              clerk_id: clerkUser.id,
              photo: clerkUser.imageUrl,
            },
          }
        )
      }

    }
  }, [
    authResult.user,
    isLoadingProfile,
    clerkUser,
  ]);

  async function signOut() {
    firebaseAuth.signOut().finally(() => {
      signOutClerk().finally(() => {
        auth.signOut();
      });
    });
  }

  if (authResult.isLoading || isLoadingProfile) {
    return {
      isLoading: true,
      error: undefined,
      profile: undefined,
      signOut,
    };
  }

  else if (error) {
    return {
      isLoading: false,
      error,
      profile: undefined,
      signOut,
    }
  }

  else if (!profile) {
    return {
      isLoading: false,
      error: { message: "User profile not found" },
      profile: undefined,
      signOut,
    }
  }

  return {
    isLoading: false,
    error: undefined,
    profile,
    signOut,
  }

}


export const AuthContext = React.createContext<ReturnType<typeof _useAuth> & { isPreloaded: boolean }>({
  isLoading: true,
  error: undefined,
  profile: undefined,
  isPreloaded: false,
  signOut: undefined,
});


export const useAuth = () => React.useContext(AuthContext);


export const AuthProvider: React.FC<{
  children: React.ReactNode,
  preloadedData?: Omit<AuthState & { profile: InstantObject | undefined, signOut: (() => Promise<void>) | undefined }, 'user'>,
}> = ({ children, preloadedData }) => {
  let { isLoading, error, profile, signOut } = _useAuth();

  let isPreloaded = false;

  if (preloadedData && isLoading) {
    isLoading = preloadedData.isLoading;
    error = preloadedData.error;
    profile = preloadedData.profile ? UserProfile.deserialize(preloadedData.profile) : undefined;
    isPreloaded = true;
  }

  return <AuthContext.Provider value={{ isLoading, error, profile, isPreloaded, signOut }}>
    <UserProfile.Provider entity={profile || null}>
      {children}
    </UserProfile.Provider>
  </AuthContext.Provider>

}
