import { EventWrappedTransactionChunk } from "../events";
import { create } from "../model";
import { getProjectListFromGroup } from "./project-group";
import { PureDoc, PureProject, PureProjectGroup, PureWorkspace } from "./pure";
import { insertProjectsAndGroupsIntoWorkspace } from "./workspace";


export function ungroupProject<
  Workspace extends PureWorkspace<PureProject, PureProjectGroup<PureProject>>,
  Project extends PureProject,
  Group extends PureProjectGroup<Project>
>(workspace: Workspace, project: Project, group?: Group): EventWrappedTransactionChunk[] {
  const foundGroup = group ?? workspace.links.group__project?.find(x => x.links.project?.some(y => y.id === project.id));
  if (!foundGroup) {
    return [];
  }
  const txs = [
    ...insertProjectsAndGroupsIntoWorkspace(workspace, [...getProjectListFromGroup(foundGroup)], { after: foundGroup }),
    ...project.unlink(foundGroup.key, foundGroup.id),
  ];
  return txs;
}


export function createDocInProject<Workspace extends PureWorkspace<PureProject, PureProjectGroup<PureProject>>, Project extends PureProject, Doc extends PureDoc>(
  workspace: Workspace,
  project: Project,
  onCreate?: (key: string, id: string) => void,
): EventWrappedTransactionChunk[] {
  return create(project.db, "doc", {
    title: "Untitled",
    content: {
      type: 'doc',
      content: [{
        type: 'paragraph',
        content: []
      }],
    },
  }, { after: (key, id) => {
    onCreate?.(key, id);
    return [
      ...workspace.link(key, id),
      ...project.link(key, id),
    ];
  }});
}