import { insertIntoListField, ListInsertPosition, sortByListField, updateListField } from "../fields";
import { EventWrappedTransactionChunk } from "../events";
import { PureProject, PureProjectGroup, PureWorkspace } from "./pure";


export function getProjectListFromGroup<ProjectGroup extends PureProjectGroup<any>>(
  projectGroup: ProjectGroup
): Array<
  NonNullable<ProjectGroup['links']['project']>[number]
> {
  const projects = projectGroup.links.project || [];

  const projectList = sortByListField(
    projects,
    projectGroup.data.list__project,
    (a, b) => (a.data.name ?? '').localeCompare(b.data.name ?? '')
  );

  return projectList;
}


export function updateProjectListInGroup<ProjectGroup extends PureProjectGroup<any>, Project extends PureProject>(
  projectGroup: ProjectGroup,
  items: Project[],
  tieBreaker?: (a: Project, b: Project) => number,
): EventWrappedTransactionChunk[] {
  const newListField = updateListField(projectGroup.data.list__project, items, tieBreaker);
  return projectGroup.merge(({ list__project: newListField }));
}


export function insertProjectsIntoGroup<ProjectGroup extends PureProjectGroup<any>, ProjectItem extends PureProject | { id: string, data: { name: string } }>(
  projectGroup: ProjectGroup,
  projects: ProjectItem[],
  position: ListInsertPosition<ProjectItem>,
): EventWrappedTransactionChunk[] {
  const currentList = getProjectListFromGroup(projectGroup);
  const newListField = insertIntoListField(projectGroup.data.list__project, currentList, projects, position, (a, b) => (a.data.name ?? '').localeCompare(b.data.name ?? ''));
  return projectGroup.merge(({ list__project: newListField }));
}


export function moveProjectsToGroup<Workspace extends PureWorkspace<Project, PureProjectGroup<Project>>, ProjectGroup extends PureProjectGroup<Project>, Project extends PureProject, ProjectItem extends PureProject | { id: string, data: { name: string } }>(
  workspace: Workspace,
  group: ProjectGroup,
  projects: ProjectItem[],
  position: ListInsertPosition<ProjectItem>,
): EventWrappedTransactionChunk[] {
  let txs: EventWrappedTransactionChunk[] = [];

  // Remove each project item from its group
  projects.forEach(project => {
    const originalGroup = workspace.links.group__project?.find(x => x.links.project?.some(y => y.id === project.id));
    if (originalGroup && originalGroup.id !== group.id) {
      txs.push(...originalGroup.unlink("project", project.id));
    }
  });

  // Update the group list
  return [
    ...projects.map(project => group.link("project", project.id)).flat(),
    ...insertProjectsIntoGroup(group, projects, position)
  ];
}