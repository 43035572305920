import { EventWrappedTransactionChunk } from "../events";
import { insertIntoListField, ListInsertPosition, sortByListField, updateListField } from "../fields";
import { create } from "../model";
import { getProjectListFromGroup } from "./project-group";
import { PureDoc, PureProject, PureProjectGroup, PureWorkspace } from "./pure";



export function getProjectAndGroupsListFromWorkspace<
  Workspace extends PureWorkspace<Project, ProjectGroup>,
  ProjectGroup extends PureProjectGroup<Project>,
  Project extends PureProject
>(
  workspace: Workspace
): Array<
  NonNullable<Workspace['links']['group__project']>[number] |
  NonNullable<Workspace['links']['project']>[number]
> {
  const groups = workspace.links.group__project || [];
  const projects = workspace.links.project || [];

  const { groupIdByProjectId } = groups.reduce(
    (acc, projectGroup) => {
      for (const project of projectGroup.links.project || []) {
        acc.groupIdByProjectId[project.id] = projectGroup.id;
      }
      return acc;
    },
    { groupIdByProjectId: {} as Record<string, string> }
  );

  const ungroupedProjects = projects.filter(
    (project) => !groupIdByProjectId[project.id]
  );

  const projectAndGroupsList = sortByListField(
    [...groups, ...ungroupedProjects],
    workspace.data.list__project__and__group__project,
    (a, b) => (a.data.name ?? '').localeCompare(b.data.name ?? '')
  );

  return projectAndGroupsList;
}


export function getFlatProjectsFromWorkspace<
  Workspace extends PureWorkspace<Project, ProjectGroup>,
  ProjectGroup extends PureProjectGroup<Project>,
  Project extends PureProject
>(
  workspace: Workspace | null
): Project[] {
  if (!workspace) {
    return [];
  }
  const projectAndGroupsList = getProjectAndGroupsListFromWorkspace(workspace);
  let projects: Project[] = [];
  for (const item of projectAndGroupsList) {
    if (item.key === "project") {
      projects.push(item);
    } else {
      projects.push(...getProjectListFromGroup(item))
    }
  }
  return projects;
}


export function updateProjectAndGroupsListInWorkspace<Workspace extends PureWorkspace<any, any>, Project extends PureProject, ProjectGroup extends PureProjectGroup<Project>>(
  workspace: Workspace,
  items: (Project | ProjectGroup)[],
  tieBreaker?: (a: Project | ProjectGroup, b: Project | ProjectGroup) => number,
): EventWrappedTransactionChunk[] {
  const newListField = updateListField(workspace.data.list__project__and__group__project, items, tieBreaker);
  return workspace.merge(({ list__project__and__group__project: newListField }));
}


export function insertProjectsAndGroupsIntoWorkspace<Workspace extends PureWorkspace<any, any>, Project extends PureProject, ProjectItem extends PureProject | { id: string, data: { name: string } }, GroupItem extends PureProjectGroup<Project> | { id: string, data: { name: string } }>(
  workspace: Workspace,
  items: (GroupItem | ProjectItem)[],
  position: ListInsertPosition<GroupItem | ProjectItem>,
): EventWrappedTransactionChunk[] {
  const currentList = getProjectAndGroupsListFromWorkspace(workspace);
  const newListField = insertIntoListField(workspace.data.list__project__and__group__project, currentList, items, position,
    // @ts-expect-error
    (a, b) => (a.data.name ?? '').localeCompare(b.data.name ?? ''));
  return workspace.merge(({ list__project__and__group__project: newListField }));
}


export function createProjectGroupInWorkspace<Workspace extends PureWorkspace<any, any>, Project extends PureProject>(
  workspace: Workspace,
  project?: Project,
): EventWrappedTransactionChunk[] {

  const existingNames = workspace.links.group__project?.map(x => x.data.name) || [];
  const baseName = (project?.data.name || "") + " Group";
  
  // Find the highest number in existing group names with the same base name
  const regex = new RegExp(`^${baseName} (\\d+)$`);
  const highestNumber = Math.max(
    0,
    ...existingNames
      .map(name => {
        const match = name?.match(regex);
        return match ? parseInt(match[1]) : 0;
      })
  );
  const name = `${baseName} ${highestNumber + 1}`;
  
  const txs = [
    create(workspace.db, "group__project", {
      name,
      list__project: project ? insertIntoListField({}, [], [project], 'start') : undefined,
    }, { after: (key, id) => [
      ...workspace.link(key, id),
      ...insertProjectsAndGroupsIntoWorkspace(workspace, [{ id, data: { name } }], project ? { after: project } : 'start'),
      ...[project ? [
        ...project.link(key, id),
      ] : []].flat(),
    ]}),
  ];

  return txs.flat();

}


export function moveProjectAndGroupsToWorkspace<
  Workspace extends PureWorkspace<Project, PureProjectGroup<Project>>,
  Project extends PureProject,
  Group extends PureProjectGroup<Project>,
  ProjectItem extends Project | Group | { key: string, id: string, data: { name: string } }
>(
  workspace: Workspace,
  items: ProjectItem[],
  position: ListInsertPosition<ProjectItem>,
): EventWrappedTransactionChunk[] {
  let txs: EventWrappedTransactionChunk[] = [];

  // Remove each project item from its group
  items.filter(x => x.key === "project").forEach(project => {
    const group = workspace.links.group__project?.find(x => x.links.project?.some(y => y.id === project.id));
    if (group) {
      txs.push(...group.unlink("project", project.id));
    }
  });

  // Update the workspace list
  txs.push(
    ...items.map(item => workspace.link(item.key, item.id)).flat(),
    ...insertProjectsAndGroupsIntoWorkspace(workspace, items, position),
  );
  return txs;
}


export function createDocInWorkspace<Workspace extends PureWorkspace<any, any, Doc>, Doc extends PureDoc>(
  workspace: Workspace,
  onCreate?: (key: string, id: string) => void,
): EventWrappedTransactionChunk[] {
  return create(workspace.db, "doc", {
    title: "Untitled",
    content: {
      type: 'doc',
      content: [{
        type: 'paragraph',
        content: []
      }],
    },
  }, { after: (key, id) => {
    onCreate?.(key, id);
    return [
      ...workspace.link(key, id),
    ];
  }});
}