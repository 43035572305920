import { getIndicesBetween, sortByIndex } from "@tldraw/indices";
import { getInsertOperations, removeDuplicates, reorderItems } from "./indices";


interface Item {
  id: string;
}


interface ListField {
  [key: string]: {
    fractional_index: string;
  };
}


export type ListInsertPosition<T extends Item> = {
  before?: string | T,
  after?: string | T,
} | number | 'start' | 'end';


export function sortByListField<T extends Item>(items: T[], listField: ListField | undefined, tieBreaker?: (a: T, b: T) => number) {
  const orderedItems = items.filter(item => !!listField?.[item.id]).sort((a, b) => {
    const aIndex = listField?.[a.id]?.fractional_index;
    const bIndex = listField?.[b.id]?.fractional_index;
    if (!aIndex || !bIndex) return tieBreaker?.(a, b) ?? 0;
    const sortValue = sortByIndex({ index: aIndex }, { index: bIndex });
    return sortValue === 0 ? (tieBreaker?.(a, b) ?? 0) : sortValue;
  });
  const unorderedItems = items.filter(item => !listField?.[item.id]).sort(tieBreaker);
  const reorderedItems = [...orderedItems, ...unorderedItems];
  return reorderedItems;
}


export function insertIntoListField<T extends Item>(
  listField: ListField | undefined,
  currentItems: T[],
  items: T[],
  position: ListInsertPosition<T>,
  tieBreaker?: (a: T, b: T) => number,
) {
  let before: string | undefined;
  let after: string | undefined;
  
  const currentList = sortByListField(currentItems, listField, tieBreaker);

  if (typeof position === 'number') {
    const index = position;
    before = index < currentList.length ? currentList[index].id : undefined;
    after = index > 0 ? currentList[index - 1].id : undefined;
  } else if (position === 'start') {
    before = currentList[0]?.id;
    after = undefined;
  } else if (position === 'end') {
    before = undefined;
    after = currentList[currentList.length - 1]?.id;
  } else {
    before = typeof position.before === 'string' ? position.before : position.before?.id;
    after = typeof position.after === 'string' ? position.after : position.after?.id;
    
    // If both before and after are specified but they don't align properly,
    // prioritize the 'after' position
    if (before && after) {
      const beforeIndex = currentList.findIndex(item => item.id === before);
      const afterIndex = currentList.findIndex(item => item.id === after);
      if (beforeIndex <= afterIndex) {
        // Position is inconsistent, prioritize 'after'
        before = afterIndex < currentList.length - 1 ? currentList[afterIndex + 1].id : undefined;
      }
    }
    else if (before && !after) {
      const beforeIndex = currentList.findIndex(item => item.id === before);
      after = beforeIndex > 0 ? currentList[beforeIndex - 1].id : undefined;
    }
    else if (after && !before) {
      const afterIndex = currentList.findIndex(item => item.id === after);
      before = afterIndex < currentList.length - 1 ? currentList[afterIndex + 1].id : undefined;
    }
  }

  // Find the insertion index
  let insertionIndex: number;
  if (after !== undefined) {
    insertionIndex = currentList.findIndex(item => item.id === after) + 1;
  } else if (before !== undefined) {
    insertionIndex = currentList.findIndex(item => item.id === before);
  } else {
    insertionIndex = currentList.length; // Default to end if no position specified
  }

  // Remove items from their current positions and insert them at the target position
  const itemIds = new Set(items.map(item => item.id));
  const listWithoutItems = currentList.filter(item => !itemIds.has(item.id));
  const finalList = [
    ...listWithoutItems.slice(0, insertionIndex),
    ...items,
    ...listWithoutItems.slice(insertionIndex)
  ];

  return updateListField(listField, finalList, tieBreaker);
}


export function updateListField<T extends Item>(listField: ListField | undefined, items: T[], tieBreaker?: (a: T, b: T) => number) {

  const currentList = sortByListField(items.filter(item => !!listField?.[item.id]), listField, tieBreaker);

  // Calculate the reordered list
  const reordered = reorderItems([...currentList, ...items], items.map(x => x.id));

  // Get the operations needed to achieve the new order
  const operations = getInsertOperations(currentList, reordered);

  const newListField: ListField = {};

  // Process each operation to calculate new fractional indices
  operations.forEach(({ startItem, endItem, items }) => {
    const startIndex = startItem ? listField?.[startItem.id]?.fractional_index : undefined;
    const endIndex = endItem ? listField?.[endItem.id]?.fractional_index : undefined;
    
    // Get new fractional indices between start and end items
    const indices = getIndicesBetween(startIndex, endIndex, items.length);
    
    // Update fractional indices for items
    items.forEach((item, i) => {
      newListField[item.id] = {
        fractional_index: indices[i]
      };
    });
  });

  return newListField;
}
