

export enum ProjectRoles {
  admin = 0,
  editor = 1,
  commenter = 2,
  reviewer = 3,
}

export const ProjectRoleLabels = {
  [ProjectRoles.admin]: "Admin",
  [ProjectRoles.reviewer]: "Reviewer",
  [ProjectRoles.editor]: "Editor",
  [ProjectRoles.commenter]: "Commenter",
}
